import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./context/UserContext";
import { HasCheckLoginContextProvider } from "./context/HasCheckLoginContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "@winylo/winylo-react-component/dist/css/index.css";
import "./index.css";
import { CategoriesContextProvider } from "./context/CategoriesContext";
import { MediasContextProvider } from "./context/MediasContext";

if (process.env.NODE_ENV === "production") {
    console.log = function () {};
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <UserContextProvider>
            <HasCheckLoginContextProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <CategoriesContextProvider>
                            <MediasContextProvider>
                                <App />
                            </MediasContextProvider>
                        </CategoriesContextProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </HasCheckLoginContextProvider>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
